import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  
  
  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WISH',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x9063ECe8577A0112FcEE5E3b8bb66fa212B020Ad', // AUTO-BNB LP
    },
    tokenSymbol: 'WISH',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x980e2937677c7af859b0a9c741370c60c0a28b26',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'WISH-BNB',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x9063ECe8577A0112FcEE5E3b8bb66fa212B020Ad', // AUTO-BNB LP
    },
    tokenSymbol: 'WISH',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x980e2937677c7af859b0a9c741370c60c0a28b26',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 2,
    risk: 5,
    lpSymbol: 'PRV-BNB',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0xCd18e1151a4831e5EbCEcC611369123012674253', // AUTO-BNB LP
    },
    tokenSymbol: 'PRV',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x7762a14082ab475c06d3868b385e46ae27017231',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 3,
    risk: 5,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0', // AUTO-BNB LP
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 4,
    risk: 5,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },


  {
    pid: 5,
    risk: 5,
    lpSymbol: 'USDC-USDT',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c', // AUTO-BNB LP //bnb -wish
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },


  {
    pid: 6,
    risk: 5,
    lpSymbol: 'USDC-BUSD',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1', // AUTO-BNB LP //
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    pid: 7,
    risk: 5,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082', // AUTO-BNB LP
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 8,
    risk: 5,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc', // AUTO-BNB LP
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 9,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 10,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },




  
 
]

export default farms
