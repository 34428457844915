export default {
  cake: {
    56: '0x980e2937677c7af859b0a9c741370c60c0a28b26',
    97: '0xFC741A2e0620e438Bfae6A7f289b1bAe3B343Daa',
  },
  masterChef: {
    56: '0x7aA3F97ce34139302D856F6861751Ce2F128a212',
    97: '0xd1984594bcFe09efd2356b17c8dD8d93F1D4Bde0',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },

  usdt:{
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
